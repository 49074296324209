var timerElements = document.querySelectorAll('.timer');

function updateTimer(timerElement) {
    var timeInSeconds = parseInt(timerElement.dataset.time, 10);

    var timerInterval = setInterval(function() {
        if (timeInSeconds <= 0) {
            clearInterval(timerInterval);
            timerElement.innerHTML = '00:00';
            return;
        }

        var minutes = Math.floor(timeInSeconds / 60);
        var seconds = timeInSeconds % 60;

        var timeString = ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

        timerElement.innerHTML = timeString;
        timeInSeconds--;
    }, 1000);
}

for (var i = 0; i < timerElements.length; i++) {
    updateTimer(timerElements[i]);
}